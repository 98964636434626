import { configureStore } from "@reduxjs/toolkit";
import NotesReducer from "./apps/notes/NotesSlice";
import CustomizerReducer from "./customizer/CustomizerSlice";
import AuthSlice from "./apps/auth/AuthSlice";
import MessageSlice from "./apps/message/Message";
import RequestSlice from "./apps/admin/UserSlice";
import SettingSlice from "../store/apps/setting/SettingSlice";
import UserSlice from "../store/apps/user/UserSlice";
import { useReducer } from "react";
import RecipientSlice from "../store/apps/recipients/RecipientSlice";
import RequestRecipientSlice from "../store/apps/request/RequestRecipientSlice";
import GroupSlice from "../store/apps/groups/GroupSlice";
import SentFilesSlice from "../store/apps/sentFiles/sentFiles";
import RecentFilesSlice from "../store/apps/Home/homes";
import UploadFolderSlice from "../store/apps/uploadFolder/uploadFolder";
import StorageSlice from "../store/apps/storage/storage";
import FileSlice from "../store/apps/files/FileSlice";

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    notesReducer: NotesReducer,
    authReducer: AuthSlice,
    messageReducer: MessageSlice,
    requestsReducer: RequestSlice,
    setting: SettingSlice,
    userReducer: UserSlice,
    recipientReducer: RecipientSlice,
    requestRecipientReducer: RequestRecipientSlice,
    groupReducer: GroupSlice,
    sentFilesReducer: SentFilesSlice,
    recentFilesReducer: RecentFilesSlice,
    uploadFolderReducr: UploadFolderSlice,
    storageReducr: StorageSlice,
    fileReducer: FileSlice,
  },
});

export default store;
