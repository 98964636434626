import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import GroupService from "../../../services/AdminServices/GroupService";

const initialState = {
  allGroupData: [],
  loading: false,
};

export const allUserGroups = createAsyncThunk(
  "group/allgroups",
  async (id, thunkAPI) => {
    debugger;
    try {
      const response = await GroupService.allUserGroups(id);

      return { data: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createGroups = createAsyncThunk(
  "group/createGroups",
  async ({ name, description, userId, imageName }, thunkAPI) => {
    try {
      debugger;
      const response = await GroupService.createGroups(
        name,
        description,
        userId,
        imageName
      );

      return { createGroup: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteGroups = createAsyncThunk(
  "group/deleteGroups",
  async (id, thunkAPI) => {
    debugger;
    try {
      const response = await GroupService.deleteGroups(id);

      return { deleteGroupData: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateGroup = createAsyncThunk(
  "groups/updateGroup",
  async (payload, { rejectWithValue }) => {
    debugger;
    try {
      const response = await GroupService.updateGroup(payload); // API call to update the group
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const GroupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(allUserGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(allUserGroups.fulfilled, (state, action) => {
        debugger;
        state.allGroupData = action.payload.data;
        state.loading = false;
      })
      .addCase(allUserGroups.rejected, (state) => {
        state.allGroupData = [];
        state.loading = false;
      })
      .addCase(createGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(createGroups.fulfilled, (state, action) => {
        // state.allGroupData = [...state.allGroupData, action.payload.data];
        state.loading = false;
      })
      .addCase(createGroups.rejected, (state) => {
        state.createGroup = [];
        state.loading = false;
      })
      .addCase(deleteGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteGroups.fulfilled, (state, action) => {
        debugger;
        // console.log("Group IDs:", state.allGroupData); // Logs all IDs in allGroupData
        // console.log("Arg (ID to delete):", action.meta.arg); // Logs the ID passed to the thunk

        // state.allGroupData = state.allGroupData.filter((group) => {
        //   console.log(
        //     "Checking group.id:",
        //     group.id,
        //     "against arg:",
        //     action.meta.arg
        //   );
        //   return group.id !== action.meta.arg;
        // });

        // state.deleteGroupData = action.payload.deleteGroupData;
        state.loading = false;
      })
      .addCase(deleteGroups.rejected, (state) => {
        state.deleteGroupData = [];
        state.loading = false;
      });
  },
});

const { reducer } = GroupSlice;
export default reducer;
