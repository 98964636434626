import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import RecipientService from "../../../services/AdminServices/RecipientService";

const initialState = {
  data: [],
  requests: [],
  loading: false,
};

export const recipientsUser = createAsyncThunk(
  "recipient/recipientsUser",
  async (userId, thunkAPI) => {
    try {
      debugger;
      const response = await RecipientService.recipientUsersWiseData(userId);
      return { data: response.data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const request = createAsyncThunk(
  "recipient/request",
  async (userId, thunkAPI) => {
    debugger;
    try {
      debugger;
      const response = await RecipientService.getNewRecipientRequests(userId);
      return { data: response.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const sendConnectionRequest = createAsyncThunk(
  `recipient/sendConnectionRequest`,
  async ({ userId, recipientId }, thunkAPI) => {
    debugger;
    try {
      const response = await RecipientService.sendConnection(
        userId,
        recipientId
      );
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return { data3: response.data.message };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchAllUsers = createAsyncThunk(
  "recipient/fetchAllUsers",
  async (thunkAPI) => {
    try {
      debugger;
      const response = await RecipientService.allUserData();
      //console.log("data1 checking")
      return { data1: response.data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const inviteUser = createAsyncThunk(
  "recipient/inviteUser",
  async ({ name, email, mobileNumber, organisation }, thunkAPI) => {
    try {
      debugger;
      const response = await RecipientService.inviteUser(
        name,
        email,
        mobileNumber,
        organisation
      );
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return { data4: response.data.message };
    } catch (error) {
      debugger;
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const blockRecipients = createAsyncThunk(
  "recipient/blockRecipients",
  async ({ userId, blockUserId }, thunkAPI) => {
    try {
      const response = await RecipientService.blockRecipients(
        userId,
        blockUserId
      );

      return { DataBlocked: response.data.message };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteRecipients = createAsyncThunk(
  "recipient/deleteRecipients",
  async ({ userId, recipientId }, thunkAPI) => {
    try {
      const response = await RecipientService.deleteRecipients(
        userId,
        recipientId
      );

      return { DataDeleted: response.data.message };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const approveRequest = createAsyncThunk(
  "recipient/approveRequest",
  async ({ requestId }, thunkAPI) => {
    try {
      const response = await RecipientService.approveRequest(requestId);
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return { approveMessage: response.data.message };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const rejectRequest = createAsyncThunk(
  "recipient/rejectRequest",
  async ({ requestId }, thunkAPI) => {
    try {
      const response = await RecipientService.rejectRequest(requestId);
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return { rejectMessage: response.data.message };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const RecipientSlice = createSlice({
  name: "recipient",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(recipientsUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(recipientsUser.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(recipientsUser.rejected, (state) => {
        state.data = [];
        state.loading = false;
      })
      .addCase(request.pending, (state) => {
        state.loading = true;
      })
      .addCase(request.fulfilled, (state, action) => {
        state.requests = action.payload;
        state.loading = false;
      })
      .addCase(request.rejected, (state) => {
        state.data = [];
        state.loading = false;
      })
      .addCase(sendConnectionRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendConnectionRequest.fulfilled, (state, action) => {
        state.data3 = action.payload.data3;
        state.loading = false;
      })
      .addCase(sendConnectionRequest.rejected, (state) => {
        state.data3 = [];
        state.loading = false;
      })
      .addCase(fetchAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.data1 = action.payload.data1;
        state.loading = false;
      })
      .addCase(fetchAllUsers.rejected, (state) => {
        state.data1 = [];
        state.loading = false;
      })
      .addCase(inviteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(inviteUser.fulfilled, (state, action) => {
        state.data4 = action.payload.data4;
        state.loading = false;
      })
      .addCase(inviteUser.rejected, (state) => {
        state.data4 = [];
        state.loading = false;
      })
      .addCase(blockRecipients.pending, (state) => {
        state.loading = true;
      })
      .addCase(blockRecipients.fulfilled, (state, action) => {
        state.DataBlocked = action.payload.DataBlocked;
        state.loading = false;
      })
      .addCase(blockRecipients.rejected, (state) => {
        state.DataBlocked = [];
        state.loading = false;
      })
      .addCase(deleteRecipients.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRecipients.fulfilled, (state, action) => {
        state.DataDeleted = action.payload.DataDeleted;
        state.loading = false;
      })
      .addCase(deleteRecipients.rejected, (state) => {
        state.DataDeleted = [];
        state.loading = false;
      })
      .addCase(approveRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveRequest.fulfilled, (state, action) => {
        state.approveMessage = action.payload.approveMessage;
        state.loading = false;
      })
      .addCase(approveRequest.rejected, (state) => {
        state.approveMessage = [];
        state.loading = false;
      })
      .addCase(rejectRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(rejectRequest.fulfilled, (state, action) => {
        state.rejectMessage = action.payload.rejectMessage;
        state.loading = false;
      })
      .addCase(rejectRequest.rejected, (state) => {
        state.rejectMessage = [];
        state.loading = false;
      });
  },
});

const { reducer } = RecipientSlice;
export default reducer;
