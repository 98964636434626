import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import RequestRecipientService from "../../../services/AdminServices/RequestRecipientService";
//import RecipientService from "../../../services/AdminServices/RecipientService";



const initialState = {
  data: [],
  //allUserData: [],
  loading: false,
};

export const requestGet = createAsyncThunk(
  "requestRecipient/requestGet",
  async (thunkAPI) => {
    try {
      const response = await RequestRecipientService.haveToApplyAPI();
      return { data: response.data.data }; 
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const requestPost = createAsyncThunk(
    "requestRecipient/requestPost",
    async (thunkAPI) => {
      try {
        const response = await RequestRecipientService.haveToApplyAPI();
        return { data: response.data.data }; 
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );


const RequestRecipientSlice = createSlice({
  name: "requestRecipient",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestGet.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestGet.fulfilled, (state, action) => {
        state.data4 = action.payload.data4; 
        state.loading = false;
      })
      .addCase(requestGet.rejected, (state) => {
        state.data4 = [];
        state.loading = false;
      })
      .addCase(requestPost.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestPost.fulfilled, (state, action) => {
        state.data4 = action.payload.data4; 
        state.loading = false;
      })
      .addCase(requestPost.rejected, (state) => {
        state.data4 = [];
        state.loading = false;
      })
  },
});

const { reducer } = RequestRecipientSlice;
export default reducer;
