import http from "../../baseUrl/httpcommon";

const requestForApproval = () => {
  debugger;
  return http.get(
    "/api/users/allusers?pageNumber=0&pageSize=10&sortBy=UserId&sortDir=desc"
  );
};

const userStatusChange = (userId, status) => {
  return http.post(`/api/users/${userId}/status?status=${status}`);
};

const RequestRecipientService = {
  requestForApproval,
  userStatusChange,
};

export default RequestRecipientService;
