import httpcommonFile from "../../baseUrl/httpcommonFile";
import httpcommonStorage from "../../baseUrl/httpcommonStorage";

const storage = (data) => {
  return httpcommonStorage.get(`/api/storage/usage`);
};

const uploadFolder = (formData,onProgress) => {
  console.log("uploadFolderSlice",formData)
  //debugger;
  return httpcommonFile.post(`/api/folders/upload`,formData,{
    headers: {
      'Content-Type': 'multipart/form-data',
  },
  onUploadProgress: (progressEvent) => {
    // Calculate the percentage of progress
    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    if (onProgress) {
      // Call the onProgress function to pass the progress value
      onProgress(progress);
    }}
  });
};
const uploadFolderService = {
  uploadFolder,
  storage
};

export default uploadFolderService;
