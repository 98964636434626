import axios from "axios";

export default axios.create({
  // baseURL: "http://localhost:8084",
  baseURL: "https://epostzap-api.digitalnoticeboard.biz",

  headers: {
    "Content-Type": "application/json",
  },
});
