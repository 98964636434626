import http from "../../baseUrl/httpcommon";

const recipientUsersWiseData = (userId) => {
  debugger;
  return http.get(`/api/users/${userId}/recipients`);
};

const getNewRecipientRequests = (userId) => {
  debugger;
  return http.get(`/api/users/users/${userId}/pending-requests`);
};

const sendConnection = (userId, recipientId) => {
  debugger;
  return http.post(`/api/users/${userId}/recipients/${recipientId}/request`);
};

const allUserData = () => {
  return http.get(
    "/api/users/allusers?pageNumber=0&pageSize=100&sortBy=UserId&sortDir=desc"
  );
};

const blockRecipients = (userId, blockUserId) => {
  return http.post(`/api/users/${userId}/block/${blockUserId}`);
};

const deleteRecipients = (userId, recipientId) => {
  return http.delete(`/api/users/${userId}/recipients/${recipientId}`);
};

const approveRequest = (requestId) => {
  return http.post(`/connect/${requestId}/approve`);
};

const rejectRequest = (requestId) => {
  return http.post(`/connect/${requestId}/reject`);
};

const inviteUser = (name, email, mobileNumber, organisation) =>
  http.post("/api/users/22/invite", {
    name,
    email,
    mobileNumber,
    organisation,
  });

const RecipientService = {
  recipientUsersWiseData,
  sendConnection,
  allUserData,
  inviteUser,
  blockRecipients,
  deleteRecipients,
  approveRequest,
  rejectRequest,
  getNewRecipientRequests,
};

export default RecipientService;
