import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import "./FileUploadModal.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Progress,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import SidebarData from "../sidebardata/SidebarData";
import Logo from "../../logo/Logo";
import { ToggleMobileSidebar } from "../../../store/customizer/CustomizerSlice";
import NavItemContainer from "./NavItemContainer";
import NavSubMenu from "./NavSubMenu";
import JSZip from "jszip";
import { LinearProgress } from "@mui/material";
import { uploadFolder } from "../../../store/apps/uploadFolder/uploadFolder";
import { setMessage } from "../../../store/apps/message/Message";
import { toast } from "react-toastify";
import { storage } from "../../../store/apps/storage/storage";

const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentURL = location.pathname.split("/").slice(0, -1).join("/");

  const activeBg = useSelector((state) => state.customizer.sidebarBg);
  const isFixed = useSelector((state) => state.customizer.isSidebarFixed);
  const toggleMiniSidebar = useSelector(
    (state) => state.customizer.isMiniSidebar
  );

  const { message, type } = useSelector((state) => state.messageReducer);

  const user = localStorage.getItem("user");

  // Parse the user data if it exists
  const parsedUser = user ? JSON.parse(user) : null;

  // Access the user's roles
  const userRoles = parsedUser?.role;
  // Extract role names from the list of role objects
  const roleNames = userRoles?.map((role) => role.name);

  const filteredSidebarData = SidebarData.filter((item) => {
    if (roleNames?.includes("ROLE_ADMIN")) {
      // Admins only see items with ROLE_ADMIN
      return item.role === "ROLE_ADMIN";
    } else if (roleNames?.includes("ROLE_CLIENT")) {
      // Clients only see items with ROLE_CLIENT
      return item.role === "ROLE_CLIENT";
    }
    // Return false by default if no roles match
    return false;
  });

  const [modal, setModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");

  const toggle = () => setModal(!modal);

  const handleFolderChange = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    setFiles([...files, ...uploadedFiles]);
  };

  const handleFileRemove = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const storedUser = JSON.parse(localStorage.getItem("user"));
  const userId = storedUser?.id;

  const handleUpload = () => {
    console.log("Files to upload:", files);

    if (files.length === 0) {
      setStatusMessage("Please select a folder.");
      return;
    }
    setStatusMessage("Creating zip file...");
    const zip = new JSZip();

    files.forEach((file) => {
      zip.file(file.webkitRelativePath, file);
    });
    // Get current timestamp
    const timestamp = new Date().toISOString().replace(/[:.-]/g, ""); // Replace symbols that are not allowed in filenames

    // Append timestamp to the filename
    const filename = `folder_${timestamp}.zip`;
    zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        setStatusMessage("Uploading zip file...");
        const formData = new FormData();
        formData.append("files", content, filename);
        formData.append("userId", "5"); // Add dynamic userId if needed

        if (userId) {
          dispatch(uploadFolder({ userId, formData }));
        }

        // const xhr = new XMLHttpRequest();
        // xhr.open('POST', 'http://192.168.1.40:8088/api/files/upload?userId=5', true);

        // xhr.upload.addEventListener('progress', (e) => {
        //     if (e.lengthComputable) {
        //         const percent = (e.loaded / e.total) * 100;
        //         setProgress(percent);
        //     }
        // });

        // xhr.onload = () => {
        //     if (xhr.status === 200) {
        //         setStatusMessage('File uploaded successfully!');
        //     } else {
        //         setStatusMessage('Error uploading file.');
        //     }
        // };

        // xhr.send(formData);
        setFiles([]);
        // toggle();
      })
      .catch((error) => {
        setStatusMessage("Error generating zip file.");
      });

    // Add your upload logic here
  };

  useEffect(() => {
    // debugger;

    if (userId) {
      dispatch(storage(userId));
    }
  }, [dispatch]);

  const { filesData, loading, progress } = useSelector(
    (state) => state.uploadFolderReducr
  );

  useEffect(() => {
    // setModal(false);
    setStatusMessage(filesData);
    if (loading == false) {
      setModal(false);
      console.log("upload loading", filesData);
    }
  }, [filesData, loading, progress]);

  useEffect(() => {
    if (message) {
      if (type === "success") {
        toast.success(message);
      } else if (type === "error") {
        toast.error(message);
      }
      // Reset the message after showing the toast to ensure it triggers again
      dispatch(setMessage({ message: "", type: "" }));
    }
  }, [message, type, dispatch]);

  const { storageData } = useSelector((state) => state.storageReducr);

  console.log("storage data", storageData);

  return (
    <div
      className={`sidebarBox shadow bg-${activeBg} ${
        isFixed ? "fixedSidebar" : ""
      }`}
    >
      <SimpleBar style={{ height: "100%" }}>
        {/********Logo*******/}
        <div className="d-flex p-3 align-items-center">
          <Logo />
          <Button
            size="sm"
            className="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none ms-auto btn-close"
            aria-label="close"
            onClick={() => dispatch(ToggleMobileSidebar())}
          />
        </div>
        {/********Sidebar Content*******/}
        {roleNames == "ROLE_ADMIN" ? (
          ""
        ) : (
          <div className="py-4 text-center profile-area">
            <Button color="blue" onClick={toggle}>
              <Icon.UploadCloud /> Upload
            </Button>
          </div>
        )}

        <div>
          <Nav vertical className={activeBg === "white" ? "" : "lightText"}>
            {filteredSidebarData.map((navi) => {
              if (navi.caption) {
                return (
                  <div
                    className="navCaption fw-bold text-uppercase mt-4"
                    key={navi.caption}
                  >
                    {navi.caption}
                  </div>
                );
              }
              if (navi.children) {
                return (
                  <NavSubMenu
                    key={navi.id}
                    icon={navi.icon}
                    title={navi.title}
                    items={navi.children}
                    suffix={navi.suffix}
                    suffixColor={navi.suffixColor}
                    // toggle={() => toggle(navi.id)}
                    // collapsed={collapsed === navi.id}
                    isUrl={currentURL === navi.href}
                  />
                );
              }
              return (
                <NavItemContainer
                  key={navi.id}
                  //toggle={() => toggle(navi.id)}
                  className={
                    location.pathname === navi.href ? "activeLink" : ""
                  }
                  to={navi.href}
                  title={navi.title}
                  suffix={navi.suffix}
                  suffixColor={navi.suffixColor}
                  icon={navi.icon}
                />
              );
            })}
          </Nav>
          <div
            style={{ padding: "0px 10px" }}
            className="py-4 text-center profile-area"
          >
            <Progress
              className="my-3"
              style={{
                height: "16px",
                borderRadius: 8,
              }}
              value={
                storageData.allocatedStorageBytes / storageData.usedStorageBytes
              }
            />
            <span>
              {storageData.usedStorageReadable} GB of{" "}
              {storageData.allocatedStorageReadable} used
            </span>
            {roleNames == "ROLE_ADMIN" ? (
              ""
            ) : (
              <Button
                color="light-primary"
                className="me-2 btn rounded-pill mt-2"
                block
                size="md"
              >
                Get more storage
              </Button>
            )}
          </div>
        </div>
      </SimpleBar>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Upload Files or Folders</ModalHeader>
        <ModalBody>
          <div className="upload-container">
            <label htmlFor="folder-upload" className="custom-file-upload">
              <i className="fas fa-folder"></i> Choose Folder
            </label>
            <input
              id="folder-upload"
              type="file"
              webkitdirectory="true"
              directory=""
              multiple
              onChange={handleFolderChange}
              style={{ display: "none" }}
            />
            {loading ? (
              <LinearProgress variant="determinate" value={progress} />
            ) : null}

            {files.length > 0 && (
              <div className="file-preview">
                {files.map((file, index) => (
                  <div key={index} className="file-item">
                    <span>{file.webkitRelativePath || file.name}</span>
                    <button
                      className="remove-btn"
                      onClick={() => handleFileRemove(index)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleUpload}
            disabled={files.length === 0}
          >
            Upload
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Sidebar;
