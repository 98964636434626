import http from "../../baseUrl/httpcommonGroup";

const allUserGroups = (id) => {
  debugger;
  return http.get(`/groups/allUserOfGroup/${id}`);
};

const createGroups = (name, description, userId, imageName) => {
  debugger;
  return http.post("/groups/create", {
    name,
    description,
    userId,
    imageName,
  });
};

const deleteGroups = (id) => {
  return http.delete(`/groups/${id}`);
};

const updateGroup = (data) => {
  debugger;
  return http.put(`/groups/update/${data.groupId}`, data);
};

const GroupService = {
  allUserGroups,
  createGroups,
  deleteGroups,
  updateGroup,
};

export default GroupService;
