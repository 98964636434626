import { Navigate } from "react-router-dom";
import BlankLayout from "../layouts/BlankLayout";
import FullLayout from "../layouts/FullLayout";
import Loadable from "../layouts/loader/Loadable";
import { lazy } from "react";

const Minimal = Loadable(lazy(() => import("../views/dashboard/Minimal")));

const PrivateRoute = Loadable(lazy(() => import("../PrivateRoute")));
const RoleBasedRoute = Loadable(lazy(() => import("../RoleBasedRoute")));
/***** Auth Pages ****/
const Error = Loadable(lazy(() => import("../views/auth/Error")));
const Login = Loadable(lazy(() => import("../views/auth/Login")));
const ForgotPassword = Loadable(
  lazy(() => import("../views/auth/ForgotPassword"))
);
const OtpVerification = Loadable(
  lazy(() => import("../views/auth/OtpVerification"))
);

const ResetPassword = Loadable(
  lazy(() => import("../views/auth/ResetPassword"))
);
const ChangePassword = Loadable(
  lazy(() => import("../views/apps/changePassword/ChangePassword"))
);
const Recipients = Loadable(
  lazy(() => import("../views/apps/recipients/Recipients"))
);
const Request = Loadable(lazy(() => import("../views/apps/request/Request")));
// const Management= Loadable(
//   lazy(() => import("../views/apps/management/smtp")),
//   lazy(() => import("../views/apps/management/companySetting")),
//   lazy(() => import("../views/apps/management/developerSetting")),
//   lazy(() => import("../views/apps/management/languagePreference")),
//   lazy(() => import("../views/apps/management/applicationSettings"))
// );

const SmtpSetting = Loadable(
  lazy(() => import("../views/apps/management/smtp"))
);
const CompanySetting = Loadable(
  lazy(() => import("../views/apps/management/companySetting"))
);
const DeveloperSetting = Loadable(
  lazy(() => import("../views/apps/management/developerSetting"))
);
const LanguagePreference = Loadable(
  lazy(() => import("../views/apps/management/languagePreference"))
);
const ApplicationSettings = Loadable(
  lazy(() => import("../views/apps/management/applicationSettings"))
);

const Maintanance = Loadable(
  lazy(() => import("../views/maintanance/Maintanance"))
);

const Groups = Loadable(lazy(() => import("../views/apps/groups/groups")));

const Settings = Loadable(
  lazy(() => import("../views/apps/userSettings/settings"))
);

const SentFiles = Loadable(lazy(() => import("../views/apps/sent/SentFiles")));

const MyFiles = Loadable(lazy(() => import("../views/apps/myFiles/MyFiles")));

const Users = Loadable(
  lazy(() => import("../views/apps/Admin/ApprovedUserList/Users"))
);
const UnauthorizedPage = Loadable(lazy(() => import("../UnauthorizedPage")));

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      {
        path: "/",
        name: "Home",
        element: <Navigate to="/dashboard/home" />,
      },
      {
        path: "/dashboard/home",
        name: "Home",
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={["ROLE_ADMIN", "ROLE_CLIENT"]}>
              <Minimal />
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },
      {
        path: "/apps/sentFiles",
        name: "Sent Files",
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={["ROLE_CLIENT"]}>
              <SentFiles />,
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },
      {
        path: "/apps/files",
        name: "MyFiles",
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={["ROLE_CLIENT"]}>
              <MyFiles />,
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },
      {
        path: "/apps/recipients",
        name: "Recipients",
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={["ROLE_CLIENT"]}>
              <Recipients />,
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },

      {
        path: "/apps/request",
        name: "Request",
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={["ROLE_CLIENT"]}>
              <Request />,
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },
      {
        path: "/apps/requests",
        name: "Requests",
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={["ROLE_ADMIN"]}>
              <Users />
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },
      {
        path: "/settings/changePassword",
        name: "Change Password",
        exact: true,
        element: <ChangePassword />,
      },
      {
        path: "/apps/groups",
        name: "Groups",
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={["ROLE_CLIENT"]}>
              <Groups />,
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },
      {
        path: "/apps/settings",
        name: "Settings",
        exact: true,
        element: <Settings />,
      },
      {
        path: "/apps/management/",
        name: "Management",
        exact: true,

        children: [
          { path: "smtp", element: <SmtpSetting /> },
          { path: "companySetting", element: <CompanySetting /> },
          { path: "developerSetting", element: <DeveloperSetting /> },
          { path: "languagePreference", element: <LanguagePreference /> },
          { path: "applicationSettings", element: <ApplicationSettings /> },
        ],
      },

      { path: "*", element: <Navigate to="/auth/404" /> },
      { path: "/unauthorized", element: <UnauthorizedPage /> },
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "404", element: <Error /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
      // { path: "registerformik", element: <RegisterFormik /> },
      { path: "login", element: <Login /> },
      { path: "forgotPwd", element: <ForgotPassword /> },
      { path: "otpverify", element: <OtpVerification /> },
      { path: "maintanance", element: <Maintanance /> },
      { path: "recoverpwd", element: <ResetPassword /> },
    ],
  },
];

export default ThemeRoutes;
