import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import SentFileService from "../../../services/AdminServices/SentFiles";

const initialState = {
  filesData: [],
  loading: false,
  error: null,
};

export const sentFilesToRecipients = createAsyncThunk(
  "files/sentFilesToRecipients",
  async (id, thunkAPI) => {
    debugger;
    try {
      const response = await SentFileService.sentFilesToRecipients(id);
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const SentFilesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sentFilesToRecipients.pending, (state) => {
        state.loading = true;
      })
      .addCase(sentFilesToRecipients.fulfilled, (state, action) => {
        // debugger
        state.filesData = action.payload;
        state.loading = false;
      })
      .addCase(sentFilesToRecipients.rejected, (state) => {
        state.filesData = [];
        state.loading = false;
      });
  },
});

const { reducer } = SentFilesSlice;
export default reducer;
