import httpcommonFile from "../../baseUrl/httpcommonFile";

const sentFilesToRecipients = (id) => {
  debugger;
  return httpcommonFile.get(`/api/files/files?userId=${id}&type=sent`);
};

const getAllFiles = (id) => {
  debugger;
  return httpcommonFile.get(`/api/files/files?userId=${id}&type=all`);
};

const SentFileService = {
  sentFilesToRecipients,
  getAllFiles,
};

export default SentFileService;
