import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import FileService from "../../../services/FileService";

const initialState = {
  OwnFiles: [],
  loading: false,
  error: null,
};

export const myFiles = createAsyncThunk(
  "myFiles/myFiles",
  async ({ id, name }, thunkAPI) => {
    debugger;
    try {
      const response = await FileService.myFiles({ id, name });
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const FileSlice = createSlice({
  name: "myFiles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(myFiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(myFiles.fulfilled, (state, action) => {
        // debugger
        state.OwnFiles = action.payload;
        state.loading = false;
      })
      .addCase(myFiles.rejected, (state) => {
        state.OwnFiles = [];
        state.loading = false;
      });
  },
});

const { reducer } = FileSlice;
export default reducer;
