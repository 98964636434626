import httpcommonFile from "../baseUrl/httpcommonFile";

const myFiles = ({ id, name }) => {
  debugger;
  return httpcommonFile.get(`/api/files/user/${id}/files/search?name=${name}`);
};

const FileService = {
  myFiles,
};

export default FileService;
