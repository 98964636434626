import * as Icon from "react-feather";

const SidebarData = [
  {
    title: "Home",
    href: "/dashboard/home",
    id: 1,
    // suffix: "4",
    suffixColor: "bg-cyan rounded-pill text-dark-white",
    icon: <Icon.Home />,
    collapisble: false,
    role: "ROLE_CLIENT",
  },
  {
    title: "My Files",
    href: "/apps/files",
    icon: <Icon.HardDrive />,
    id: 2.1,
    collapisble: false,
    role: "ROLE_CLIENT",
  },
  {
    title: "Recipients",
    href: "/apps/recipients",
    icon: <Icon.Users />,
    id: 2.3,
    collapisble: false,
    role: "ROLE_CLIENT",
  },
  {
    title: "Request",
    href: "/apps/request",
    icon: <Icon.Users />,
    id: 2.4,
    collapisble: false,
    role: "ROLE_CLIENT",
  },
  {
    title: "Sent Files",
    href: "/apps/sentfiles",
    icon: <Icon.Send />,
    id: 2.5,
    collapisble: false,
    role: "ROLE_CLIENT",
  },

  {
    title: "Requests",
    href: "/apps/requests",
    icon: <Icon.User />,
    id: 2.6,
    collapisble: false,
    role: "ROLE_ADMIN",
  },
  {
    title: "Groups",
    href: "/apps/groups",
    icon: <Icon.Users />,
    id: 6.5,
    collapisble: false,
    role: "ROLE_CLIENT",
  },
  {
    title: "Settings",
    href: "/apps/settings",
    icon: <Icon.Settings />,
    id: 6.5,
    collapisble: false,
    role: "ROLE_ADMIN",
  },

  {
    title: "Management",
    icon: <Icon.Briefcase />,
    id: 6.6,
    collapsible: false,
    role: "ROLE_ADMIN",
    children: [
      {
        title: "SMTP",
        href: "/apps/management/smtp",
        icon: <Icon.UserCheck />,
        id: 6.7,
        collapsible: true,
        role: "ROLE_ADMIN",
      },
      {
        title: "Company Setting",
        href: "/apps/management/companySetting",
        icon: <Icon.File />,
        id: 6.8,
        collapsible: true,
        role: "ROLE_ADMIN",
      },
      {
        title: "Developer Setting",
        href: "/apps/management/developerSetting",
        icon: <Icon.UserCheck />,
        id: 6.9,
        collapsible: true,
        role: "ROLE_ADMIN",
      },
      // {
      //   title: "Language Preference",
      //   href: "/apps/management/languagePreference",
      //   icon: <Icon.UserCheck />,
      //   id: 7.0,
      //   collapsible: true,
      //   role: "ROLE_ADMIN",
      // },
      {
        title: "Application Settings",
        href: "/apps/management/applicationSettings",
        icon: <Icon.UserCheck />,
        id: 7.1,
        collapsible: true,
        role: "ROLE_ADMIN",
      },
    ],
  },

  {
    title: "Storage",
    href: "/auth/maintanance",
    icon: <Icon.Cloud />,
    id: 6.5,
    collapisble: false,
    role: "ROLE_CLIENT",
  },
];

export default SidebarData;
