import http from "../../baseUrl/httpcommon";

const changePassword = (email, oldPassword, newPassword) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user?.accessToken;

  return http.post("/api/users/changepassword", {
    email,
    oldPassword,
    newPassword,
  });
};

const getSmtp = () => {
  return http.get("/smtp/get");
};

const updateSmtp = (id, username, password, port, host, enableSsl) => {
  debugger;
  return http.put("/smtp/update", {
    id,
    username,
    password,
    port,
    host,
    enableSsl,
  });
};

const updateDeveloper = (
  id,
  webMasterMail,
  developerEmail,
  copyToWebMaster,
  testMode,
  copyToDeveloper
) => {
  debugger;
  return http.put("/developer/update", {
    id,
    webMasterMail,
    developerEmail,
    copyToWebMaster,
    testMode,
    copyToDeveloper,
  });
};

const sendTestNotification = (email) => {
  debugger;
  return http.post(`/smtp/test/${email}`);
};

const getApplication = () => {
  return http.get("/settings/app/application");
};
const getCompanySettings = () => {
  return http.get("/settings/company");
};

const getDeveloperSettings = () => {
  debugger;
  return http.get("/developer/get");
};

const updateCompany = (
  id,
  name,
  address,
  phoneNo,
  supportEmail,
  skypeId,
  regYear,
  fromYear
) => {
  return http.put("/settings/company", {
    id,
    name,
    address,
    phoneNo,
    supportEmail,
    skypeId,
    regYear,
    fromYear,
  });
};

const appSettingsUpdate = (
  id,
  fullName,
  shortName,
  applicationUrl,
  apiUrl,
  facebookUrl,
  youtubeUrl,
  instagramUrl,
  twitterUrl,
  adminUrl,
  companyUrl,
  documentUrl,
  userImagesUrl
) => {
  return http.put("/settings/app/application", {
    id,
    fullName,
    shortName,
    applicationUrl,
    apiUrl,
    facebookUrl,
    youtubeUrl,
    instagramUrl,
    twitterUrl,
    adminUrl,
    companyUrl,
    documentUrl,
    userImagesUrl,
  });
};

const SettingService = {
  changePassword,
  getSmtp,
  updateSmtp,
  getApplication,
  sendTestNotification,
  getCompanySettings,
  updateCompany,
  getDeveloperSettings,
  updateDeveloper,
  appSettingsUpdate,
};

export default SettingService;
