import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import uploadFolderService from "../../../services/AdminServices/uploadFolder";

const initialState = {
  storageData: [],
  loading: false,
  error: null,
};

export const storage = createAsyncThunk(
  "files/storage",
  async (thunkAPI) => {
    //debugger
    try {
      const response = await uploadFolderService.storage();
      console.log("storage response",response)
      return response.data;
    } catch (error) {
      console.log("storage response error",error)
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
      
    }
  }
);

const StorageSlice = createSlice({
  name: "files",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(storage.pending, (state) => {
        state.loading = true;
      })
      .addCase(storage.fulfilled, (state, action) => {
       // debugger
        state.storageData = action.payload;
        state.loading = false;
      })
      .addCase(storage.rejected, (state) => {
        state.storageData = [];
        state.loading = false;
      });
  },
});

const { reducer } = StorageSlice;
export default reducer;
