import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import SentFileService from "../../../services/AdminServices/SentFiles";

const initialState = {
  filesData: [],
  loading: false,
  error: null,
};

export const getAllFiles = createAsyncThunk(
  "files/getAllFiles",
  async (id, thunkAPI) => {
    debugger;
    try {
      debugger;
      const response = await SentFileService.getAllFiles(id);
      return response.data; // Ensure that this is the correct data structure
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message); // Send error message for better debugging
    }
  }
);

const RecentFilesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllFiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFiles.fulfilled, (state, action) => {
        debugger;
        console.log("Files data received:", action.payload);
        state.filesData = action.payload;
        state.loading = false;
      })
      .addCase(getAllFiles.rejected, (state, action) => {
        console.error("Error fetching files:", action.payload);
        state.filesData = [];
        state.loading = false;
      });
  },
});

const { reducer } = RecentFilesSlice;
export default reducer;
