import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import uploadFolderService from "../../../services/AdminServices/uploadFolder";

const initialState = {
  filesData: [],
  loading: false,
  error: null,
  progress: 0,
};

export const uploadFolder = createAsyncThunk(
  "files/uploadFolder",
  async ({userId,formData},thunkAPI,) => {
    
    //debugger
    try {
      const handleProgress = (progress) => {
        thunkAPI.dispatch(updateProgress(progress));  // Dispatch progress to Redux store
      };
      
      const response = await uploadFolderService.uploadFolder(formData,handleProgress);
      thunkAPI.dispatch(
        setMessage({ message: response.data, type: "success" })
      );
      console.log("files/uploadFolder",response)
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
        thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const UploadFolderSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    updateProgress: (state, action) => {
      state.progress = action.payload; // Store the progress in the state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFolder.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadFolder.fulfilled, (state, action) => {
        //debugger
        state.filesData = action.payload;
        state.loading = false;
        state.progress = 0;
      })
      .addCase(uploadFolder.rejected, (state) => {
        state.filesData = [];
        state.loading = false;
        state.progress = 0;
      });
  },
});
export const { updateProgress } = UploadFolderSlice.actions;
const { reducer } = UploadFolderSlice;
export default reducer;
