import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "../message/Message";
import SettingService from "../../../services/AdminServices/SettingService";

const initialState = {
  isLoggedIn: false,
  user: null,
  loading: false,
};

export const changePassword = createAsyncThunk(
  "setting/changePassword",
  async ({ email, oldPassword, newPassword }, thunkAPI) => {
    debugger;
    try {
      const response = await SettingService.changePassword(
        email,
        oldPassword,
        newPassword
      );
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return response.data;
    } catch (error) {
      debugger;
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSmtp = createAsyncThunk("setting/getSmtp", async (thunkAPI) => {
  try {
    const response = await SettingService.getSmtp();

    return { smtpData1: response.data.data };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const getCompanySettings = createAsyncThunk(
  "setting/getCompanySettings",
  async (thunkAPI) => {
    try {
      const response = await SettingService.getCompanySettings();

      return { data: response.data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDeveloperSettings = createAsyncThunk(
  "setting/getDeveloperSettings",
  async (thunkAPI) => {
    debugger;
    try {
      const response = await SettingService.getDeveloperSettings();

      return { data: response.data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateSmtp = createAsyncThunk(
  "setting/updateSmtp",
  async ({ id, username, password, port, host, enableSsl }, thunkAPI) => {
    debugger;
    try {
      const response = await SettingService.updateSmtp(
        id,
        username,
        password,
        port,
        host,
        enableSsl
      );
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return { updateSmtpData1: response.data.message };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "setting/updateCompany",
  async (
    { id, name, address, phoneNo, supportEmail, skypeId, regYear, fromYear },
    thunkAPI
  ) => {
    debugger;
    try {
      const response = await SettingService.updateCompany(
        id,
        name,
        address,
        phoneNo,
        supportEmail,
        skypeId,
        regYear,
        fromYear
      );
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return { updateCompany: response.data.message };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendTestNotification = createAsyncThunk(
  "setting/testNotification",
  async ({ email }, thunkAPI) => {
    debugger;
    debugger;
    try {
      const response = await SettingService.sendTestNotification(email);
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return { updateSmtpData1: response.data.message };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getApplication = createAsyncThunk(
  "setting/getApplication",
  async (thunkAPI) => {
    try {
      debugger;
      const response = await SettingService.getApplication();

      return { data: response.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateDeveloper = createAsyncThunk(
  "setting/updateDeveloper",
  async (
    {
      id,
      webMasterMail,
      developerEmail,
      copyToWebMaster,
      testMode,
      copyToDeveloper,
    },
    thunkAPI
  ) => {
    debugger;
    try {
      const response = await SettingService.updateDeveloper(
        id,
        webMasterMail,
        developerEmail,
        copyToWebMaster,
        testMode,
        copyToDeveloper
      );
      thunkAPI.dispatch(
        setMessage({ message: response.data.message, type: "success" })
      );
      return { updateCompany: response.data.message };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const applicationUpdate = createAsyncThunk(
  "setting/applicationUpdate",
  async (
    {
      id,
      fullName,
      shortName,
      applicationUrl,
      apiUrl,
      facebookUrl,
      youtubeUrl,
      instagramUrl,
      twitterUrl,
      adminUrl,
      companyUrl,
      documentUrl,
      userImagesUrl,
    },
    thunkAPI
  ) => {
    debugger;
    try {
      const response = await SettingService.appSettingsUpdate(
        id,
        fullName,
        shortName,
        applicationUrl,
        apiUrl,
        facebookUrl,
        youtubeUrl,
        instagramUrl,
        twitterUrl,
        adminUrl,
        companyUrl,
        documentUrl,
        userImagesUrl
      );
      thunkAPI.dispatch(
        setMessage({ message: "Updated successfully", type: "success" })
      );
      return { data: response.data.message };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: "error" }));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const SettingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to change password";
      })
      .addCase(getSmtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSmtp.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.smtpData1 = action.payload.smtpData1;
      })
      .addCase(getSmtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to change password";
        state.smtpData1 = [];
      })
      .addCase(getCompanySettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompanySettings.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.data = action.payload;
      })
      .addCase(getCompanySettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to change password";
        state.smtpData1 = [];
      })
      .addCase(getDeveloperSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDeveloperSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.data = action.payload;
      })
      .addCase(getDeveloperSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to change password";
        state.smtpData1 = [];
      })
      .addCase(updateSmtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSmtp.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.updateSmtpData1 = action.payload.updateSmtpData1;
      })
      .addCase(updateSmtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to change password";
        state.updateSmtpData1 = [];
      })
      .addCase(updateCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.updateCompany = action.payload.updateCompany;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to change password";
        state.updateSmtpData1 = [];
      })

      .addCase(applicationUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(applicationUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.data = action.payload.data;
      })
      .addCase(applicationUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to change password";
        state.updateSmtpData1 = [];
      })
      .addCase(getApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getApplication.fulfilled, (state, action) => {
        debugger;
        state.loading = false;
        state.isLoggedIn = true;
        state.data = action.payload;
      })
      .addCase(getApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to change password";
        state.data = [];
      })
      .addCase(sendTestNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendTestNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
      })
      .addCase(sendTestNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to Send test notification";
      });
  },
});

const { reducer } = SettingSlice;
export default reducer;
